const en = {
	// Warning messages
	'2040': { type: 'attention', text: 'The update reason will not be saved as no edits were made.' },

	// Login messages
	'401': { type: 'error', text: 'Your session has expired, please login again' },

	// Access related messages 1001 to 1100
	'1002': { type: 'error', text: 'You are not able to login due to invalid token.' },
	'1003': { type: 'error', text: 'Token could not be verified from Redis client.' },
	'1004': { type: 'error', text: 'Your session has expired. Please logout, then login again.' },
	'1005': { type: 'error', text: 'Token could not be verified from server, please try again' },
	'1006': { type: 'error', text: 'Azure Session could not be verified from server, please try again' },
	'1010': { type: 'error', text: 'User name or password missing.' },
	'1011': { type: 'error', text: 'Error occured during login. Please try again.' },
	'1012': { type: 'error', text: 'Invalid user name or password.' },
	'1013': { type: 'error', text: 'Privilege not assigned on this role.' },
	'1014': { type: 'error', text: 'Access denied.' },

	// Success msg // start 2000 - 3000
	'2001': { type: 'success', text: 'Record updated successfully.' },
	'2023': { type: 'success', text: 'Record created successfully.' },
	'2024': { type: 'error', text: 'Error in Saving Member' },
	'2025': { type: 'success', text: 'Reminder set successfully.' },
	'2026': { type: 'success', text: 'You have been successfully added to the watcher list.' },
	'2027': { type: 'success', text: 'Watchers added successfully.' },
	'2028': { type: 'success', text: 'You have been successfully removed from the watcher list.' },
	'2029': { type: 'success', text: 'Comment added successfully.' },
	'2030': { type: 'success', text: 'Comment updated successfully.' },
	'2031': { type: 'success', text: 'Comment removed successfully.' },
	'2032': { type: 'success', text: 'Task cloned successfully.' },
	'2033': { type: 'success', text: 'Role added successfully.' },
	'2034': { type: 'success', text: 'Role updated successfully.' },
	'2035': { type: 'success', text: 'Role\'s privilege updated successfully.' },
	'2036': { type: 'success', text: 'Documents uploaded successfully.' },
	'2037': { type: 'success', text: 'Data copied successfully.' },
	'2038': { type: 'success', text: 'Preference set successfully.' },
	'2039': { type: 'success', text: 'Record added successfully.' },
	'2041': { type: 'success', text: 'Member Committees updated successfully.' },
	'2042': { type: 'success', text: 'Member Committee Details updated successfully.' },
	'2043': { type: 'success', text: 'Meeting Sequence deleted successfully.' },
	'2044': { type: 'success', text: 'Committee deleted successfully.' },
	'2045': { type: 'success', text: 'Members copied successfully.' },
	'2046': { type: 'success', text: 'Committee inactivated successfully.' },
	'2047': { type: 'success', text: 'Committee reactivated successfully.' },
	'2048': { type: 'success', text: 'Selected member(s) removed successfully.' },
	'2049': { type: 'success', text: 'Showing event list from <from date> to <to date>' },
	'2050': { type: 'success', text: 'No events found for the selected date range. Please try again with a different date range.' },
	'2051': { type: 'success', text: 'No data found for the selected parameters. Please try again with different parameter selections.' },
	'2052': { type: 'attention', text: 'No information will be saved as no edits were made.' },
	'2053': { type: 'error', text: 'Please remove committee association for Informational membership type' },
	'2054': { type: 'error', text: 'Please add committee association for Participating membership type.' },
	'2055': { type: 'error', text: 'Informational Member is not entitled to free volume benefits. Please update membership type.' },
	'2056': { type: 'error', text: 'Please add organization association for Cooperative Agreement membership type.' },
	'2057': { type: 'error', text: 'Please update the Organizational details for Change of Organization or uncheck the checkbox to process the order' },
	'2058': { type: 'attention', text: 'Purchased stock code is incorrect for this order.' },
	'2059': { type: 'attention', text: 'Membership Type is not eligible to reinstate.' },
	'2060': { type: 'attention', text: 'Sorry! The error accounts cannot be reinstated.' },
	'2061': { type: 'success', text: 'Do you want to remove existing ballot deadline dates related to this meeting?' },
	'2062': { type: 'success', text: 'This action will remove all associated ballot deadline dates.' },
	'2063': { type: 'error', text: 'Ballot Deadline Date should be greater than 14 days from the current date.' },
	'2064': { type: 'error', text: 'Meeting Date should be greater than Ballot Deadline.' },

	// User Manage related code
	'2500': { type: 'error', text: 'Username already exists.' },
	'2501': { type: 'error', text: 'Please provide the Username.' },
	'2502': { type: 'error', text: 'Spaces are not allowed.' },
	'2503': { type: 'error', text: 'No permissions assigned. Please assign at least one permission.' },
	'2508': { type: 'error', text: 'Only alphanumeric characters are allowed.' },
	'2509': { type: 'error', text: 'Please provide the Password.' },
	'2510': { type: 'error', text: 'Please provide the confirm Password.' },
	'2511': { type: 'error', text: 'Password requires one lower case letter, one upper case letter, one digit, 8-16 length, and no spaces.' },
	'2512': { type: 'error', text: 'Password and confirm password are not matched.' },
	'2513': { type: 'error', text: 'Password could not be reset, please try again.' },
	'2514': { type: 'error', text: 'Error occurred while fetching data from azure ad.' },
	'2515': { type: 'error', text: 'User doesn\'t exist in Azure AD.' },
	// Role Mange related code
	'2504': { type: 'error', text: 'Role Name already exists.' },
	'2505': { type: 'error', text: 'Please provide the Role Name.' },
	'2506': { type: 'error', text: 'Error occurred while listing Member grid' },
	'2507': { type: 'error', text: 'Error occurred while listing Organization grid' },
	// Error msg // start 3000 to 4000
	'3000': { type: 'error', text: 'No record created.' },
	'3001': { type: 'error', text: 'Error occurred while adding task.' },
	'3002': { type: 'error', text: 'Start Date is required' },
	'3003': { type: 'error', text: 'Start Date is invalid' },
	'3004': { type: 'error', text: 'End Date is required' },
	'3005': { type: 'error', text: 'End Date is invalid' },
	'3010': { type: 'error', text: 'Error occurred while getting task details.' },
	'3011': { type: 'error', text: 'Error occurred while updating task assignee/status.' },
	'3012': { type: 'error', text: 'Error occurred while updating task details.' },
	'3013': { type: 'error', text: 'Error occurred while getting tasks.' },
	'3014': { type: 'error', text: 'Error occurred while removing task dependency.' },
	'3015': { type: 'error', text: 'Error occurred while listing task status.' },
	'3016': { type: 'error', text: 'Error occurred while listing renewal years.' },
	'3017': { type: 'error', text: 'Error occurred while listing tasks for dependency.' },
	'3018': { type: 'error', text: 'Error occurred while listing depends on tasks.' },
	'3019': { type: 'error', text: 'Error occurred while listing dependent tasks.' },
	'3020': { type: 'error', text: 'Error occurred while removing depends on task.' },
	'3021': { type: 'error', text: 'Error occurred while listing users.' },
	'3022': { type: 'error', text: 'Error occurred while adding depends on tasks.' },
	'3023': { type: 'error', text: 'Reminder Date is required.' },
	'3024': { type: 'error', text: 'You are not allowed to set reminder for past date.' },
	'3025': { type: 'error', text: 'Reminder notification days cannot be greater than Reminder Date.' },
	'3026': { type: 'error', text: 'Error occurred while setting reminder.' },
	'3027': { type: 'error', text: 'Error occurred while adding watchers.' },
	'3028': { type: 'error', text: 'Error occurred while removing watcher.' },
	'3029': { type: 'error', text: 'Error occurred while saving communication log.' },
	'3030': { type: 'error', text: 'Error occurred while updating communication log.' },
	'3031': { type: 'error', text: 'Error occurred while getting Task watchers list.' },
	'3032': { type: 'error', text: 'Error occurred while removing communication log.' },
	'3033': { type: 'error', text: 'Error occurred while uploading documents.' },
	'3034': { type: 'error', text: 'Error occurred while getting documents.' },
	'3035': { type: 'error', text: 'Reminder Date is invalid.' },
	'3036': { type: 'error', text: 'Error occurred while cloning documents.' },
	'3037': { type: 'error', text: 'Error occurred while removing document.' },
	'3038': { type: 'error', text: 'Error occurred while exporting data.' },
	'3039': { type: 'error', text: 'Error occurred while getting member list.' },
	'3040': { type: 'error', text: 'Error occurred while getting audit logs.' },
	'3041': { type: 'error', text: 'Error occurred while getting field list.' },
	'3042': { type: 'error', text: 'Error occurred while getting member first name list.' },
	'3043': { type: 'error', text: 'Error occurred while getting organization list.' },
	'3044': { type: 'error', text: 'Error occurred while getting organization Name.' },
	'3045': { type: 'error', text: 'Error occurred while getting representative First Name.' },
	'3046': { type: 'error', text: 'Error occurred while getting member details.' },
	'3047': { type: 'error', text: 'Error occurred while exporting member data.' },
	'3048': { type: 'error', text: 'Error occurred while exporting organization data.' },
	'3049': { type: 'error', text: 'Error occurred while getting Government Type data.' },
	'3050': { type: 'error', text: 'Error occurred while getting Master data.' },
	'3051': { type: 'error', text: 'Error occurred while updating Member data.' },
	'3052': { type: 'error', text: 'Error occurred while getting Membership type data.' }, // Use it in future for some other API
	'3053': { type: 'error', text: 'Error occurred while getting MOU contact code data.' },
	'3054': { type: 'error', text: 'Error occurred while getting interested committee data.' },
	'3055': { type: 'error', text: 'Error occurred while saving grid preference.' },
	'3056': { type: 'error', text: 'Error occurred while getting representative history.' },
	'3057': { type: 'error', text: 'Error occurred while getting communication logs.' },
	'3058': { type: 'error', text: 'Error occurred while getting representative Last Name.' },
	'3059': { type: 'error', text: 'Error occurred while getting order history.' },
	'3060': { type: 'error', text: 'Error occurred while getting active member list.' },
	'3061': { type: 'error', text: 'Error occurred while updating organization details.' },
	'3062': { type: 'error', text: 'Error occurred while getting committee list data.' },
	'3063': { type: 'error', text: 'Error occurred while getting Historical reason list.' },
	'3064': { type: 'error', text: 'Error occurred while getting committee grid preferences data.' },
	'3065': { type: 'error', text: 'Error occurred while getting committee type data.' },
	'3066': { type: 'error', text: 'Error occurred while getting Member-Committee-Information details.' },
	'3067': { type: 'error', text: 'Error occurred while getting Member-MainCommittees.' },
	'3068': { type: 'error', text: 'Error occurred while getting audit log field list.' },
	'3069': { type: 'error', text: 'Error occurred while getting Officer title.' },
	'3070': { type: 'error', text: 'Error occurred while getting Member Main/Sub-Committees.' },
	'3071': { type: 'error', text: 'Error occurred while getting Committee Level list.' },
	'3072': { type: 'error', text: 'Error occurred while getting master data for committee.' },
	'3073': { type: 'error', text: 'Error occurred while getting Member Committee details.' },
	'3074': { type: 'error', text: 'Error occurred while getting master data for no vote reasons.' },
	'3075': { type: 'error', text: 'Error occurred while getting master data for officer title.' },
	'3076': { type: 'error', text: 'Date Joined is required.' },
	'3077': { type: 'error', text: 'Date Joined is invalid' },
	'3078': { type: 'error', text: 'Officer Appointed Date is required.' },
	'3079': { type: 'error', text: 'Officer Appointed Date is invalid' },
	'3080': { type: 'error', text: 'Vote Date is required' },
	'3081': { type: 'error', text: 'Vote Date is invalid' },
	'3082': { type: 'error', text: 'Error occurred while adding member committee.' },
	'3083': { type: 'error', text: 'Error occurred while getting master data for classification.' },
	'3084': { type: 'error', text: 'Officer Appointed Date should not be future date.' },
	'3085': { type: 'error', text: 'Error occurred while updating Member Committee Details.' },
	'3086': { type: 'error', text: 'Error occurred while getting Common Committee Classification Type or No Vote Reason.' },
	'3087': { type: 'error', text: 'Error occurred while getting Account History Details.' },
	'3088': { type: 'error', text: 'Error occurred while getting Member Committee Officer Responsibilities.' },
	'3089': { type: 'error', text: 'Error occurred while updating Member Committee Officer Responsibilities.' },
	'3090': { type: 'error', text: 'Error occurred while getting all committees hierarchy details.' },
	'3091': { type: 'error', text: 'Error occurred while updating Member Committees.' },
	'3092': { type: 'error', text: 'Error occurred while validating committee designation.' },
	'3093': { type: 'error', text: 'Error occurred while adding new committee.' },
	'3094': { type: 'error', text: 'Error occurred while getting committee details.' },
	'3095': { type: 'error', text: 'Error occurred while updating committee.' },
	'3096': { type: 'error', text: 'Error occurred while updating Member Committee Details.' },
	'3097': { type: 'error', text: 'Error occurred while getting Committee Meeting Sequence.' },
	'3098': { type: 'error', text: 'Error occurred while deleting Committee Meeting Sequence.' },
	'3099': { type: 'error', text: 'Error occurred while adding Committee Meeting Sequence.' },
	'3100': { type: 'error', text: 'Error occurred while getting Meeting Types.' },
	'3101': { type: 'error', text: 'Error occured while updating member committee general information.' },
	'3102': { type: 'error', text: 'Error occurred while adding Committee Meeting Date.' },
	'3103': { type: 'error', text: 'Error occurred while getting Committee Meeting Date List.' },
	'3104': { type: 'error', text: 'Error occurred while updating Committee Meeting Date Data.' },
	'3105': { type: 'error', text: 'Error occured while getting Search Member.' },
	'3107': { type: 'error', text: 'Error occurred while getting  Roster Committee Member List.' },
	'3108': { type: 'error', text: 'Selected year not allowed.' },
	'3109': { type: 'error', text: 'Error occurred while getting Classification List.' },
	'3110': { type: 'error', text: 'Error occurred while updating Classification List.' },
	'3111': { type: 'error', text: 'You are attempting to de-link the classification which is used in other records. This is not allowed.' },
	'3112': { type: 'error', text: 'Error occurred while updating committee roster.' },
	'3113': { type: 'error', text: 'Classification Assigned Date should not be less than Committee Join Date.' },
	'3114': { type: 'error', text: 'Vote Assigned Date should not be less than Committee Join Date.' },
	'3115': { type: 'error', text: 'Classification Assigned Date is required' },
	'3116': { type: 'error', text: 'Error occurred while updating committee settings.' },
	'3117': { type: 'error', text: 'Error occurred while deleting committee.' },
	'3118': { type: 'error', text: 'Committee cannot be deleted as it has sub committees.' },
	'3119': { type: 'error', text: 'Committee cannot be deleted as it has members.' },
	'3120': { type: 'error', text: 'Committee cannot be deleted as other records are associated with it.' },
	'3121': { type: 'error', text: 'Error occured while getting sub committees details.' },
	'3122': { type: 'error', text: 'Error occured while getting offier title list on committee.' },
	'3123': { type: 'error', text: 'Error occured while getting Member  list on committee.' },
	'3124': { type: 'error', text: 'Error occured while updating committee activity.' },
	'3125': { type: 'error', text: 'Error occured while getting committee activity list.' },
	'3126': { type: 'error', text: 'Error occurred while updating committee Officer title.' },
	'3127': { type: 'error', text: 'This action will remove the information saved for the @PrimaryActivityName. Do you want to continue?' },
	'3128': { type: 'error', text: 'Invalid Email.' },
	'3129': { type: 'error', text: 'Error occured while getting application privilege data of committee type.' },
	'3130': { type: 'error', text: 'Error occurred while updating committee enable on web.' },
	'3131': { type: 'error', text: 'Error occurred while getting associated committee list.' },
	'3132': { type: 'error', text: 'Error occurred while updating responsibilities details.' },
	'3133': { type: 'error', text: 'Error occurred while getting Bylaws Revision History List.' },
	'3134': { type: 'error', text: 'Error occurred while getting Active Committees.' },
	'3135': { type: 'error', text: 'Please enter a committee.' },
	'3136': { type: 'error', text: 'Error occurred while getting Active Members In Committee.' },
	'3137': { type: 'error', text: 'Error occurred while copying Committee Members.' },
	'3138': { type: 'error', text: 'Error occurred while inactivating Committee.' },
	'3139': { type: 'error', text: 'Error occurred while getting Bylaws History Details.' },
	'3140': { type: 'error', text: 'Error occurred while reactivating Committee.' },
	'3141': { type: 'error', text: 'Error occurred while getting Country List.' },
	'3142': { type: 'error', text: 'Error occurred while getting States List.' },
	'3143': { type: 'error', text: 'Error occurred while getting committee list and primary activities.' },
	'3144': { type: 'error', text: 'Error occured while updating member nick name.' },
	'3145': { type: 'error', text: 'Error occurred while fetching company\'s details of member.' },
	'3146': { type: 'error', text: 'Error occurred while fetching members list of the company.' },
	'3147': { type: 'error', text: 'Error occurred while checking company already exists or not.' },
	'3148': { type: 'error', text: 'Error occurred while getting Bos Volume.' },
	'3149': { type: 'error', text: 'Error occurred while getting committee list.' },
	'3150': { type: 'error', text: 'Error occurred while getting primary activities.' },
	'3151': { type: 'error', text: 'Error occurred while getting associated members.' },


	// UI validations related errors
	'4001': { type: 'error', text: 'Incorrect input.' },
	'4002': { type: 'error', text: "'To' date cannot be less than 'From' date." },
	'4003': { type: 'error', text: 'Incorrect date format.' },
	'4004': { type: 'error', text: 'Graduation Date should be future date.' },
	'4005': { type: 'error', text: 'Please select Committee Type.' },
	'4006': { type: 'error', text: 'Please select Committee Hierarchy.' },
	'4007': { type: 'error', text: '@FieldName is required.' },
	'4008': { type: 'error', text: 'Committee Designation is required.' },
	'4009': { type: 'error', text: 'Date Formed is required.' },
	'4010': { type: 'error', text: 'Please select Classification.' },
	'4011': { type: 'error', text: 'Title is required.' },
	'4012': { type: 'error', text: 'Designation already exists.' },
	'4013': { type: 'error', text: 'Committee type is required.' },
	'4014': { type: 'error', text: 'Invalid committee id.' },
	'4015': { type: 'error', text: 'Last Bylaws Revision Date cannot be a future date.' },
	'4016': { type: 'error', text: "'@Date' date cannot be greater than Current date." },
	'4017': { type: 'error', text: "Meeting Month already exists." },
	'4018': { type: 'error', text: "Please select Month." },
	'4019': { type: 'error', text: "Please select Meeting Type." },
	'4020': { type: 'error', text: "Meeting Date is required." },
	'4021': { type: 'error', text: "Input Date should be greater than 01/01/1900." },
	'4022': { type: 'error', text: "Please enter valid committee designation." },
	'4023': { type: 'error', text: "Please enter minimum <number> characters." },
	'4024': { type: 'error', text: "You must enter a replaced by standard designation" },
	'4025': { type: 'error', text: "Please enter valid address." },
	'4026': { type: 'error', text: "Address already exists." },
	'4027': { type: 'error', text: 'BOS Volume Location is required.' },

	// Success msg // start 5000 to 6000-- for user
	'5015': { type: 'success', text: 'User Privilege added successfully.' },
	'5016': { type: 'success', text: 'User Privilege updated successfully.' },
	'5017': { type: 'success', text: 'User Role updated successfully.' },
	'5018': { type: 'success', text: 'Password changes successfully.' },
	'5019': { type: 'success', text: 'Description updated successfully.' },
	// Info message 6001 to 7000
	'6007': { type: 'info', text: 'No Role added.' },
	'6008': { type: 'info', text: 'No default Privilege added.' },
	'6009': { type: 'info', text: 'No Privilege for this role added.' },
	'6010': { type: 'info', text: 'No Privilege for this user added.' },
	'6011': { type: 'info', text: 'No User added.' },

	'7025': { type: 'error', text: 'Error occurred while getting membership type data.' },

	// User Manage related error
	'7029': { type: 'error', text: 'Error occurred while fetching role list data' },
	'7030': { type: 'error', text: 'Error occurred while fetching user detail data' },
	'7031': { type: 'error', text: 'Error occurred while fetching default privilege data' },
	'7032': { type: 'error', text: 'Error occurred while fetching privilege list by role Id data' },
	'7033': { type: 'error', text: 'Error occurred while fetching privilege list by user Id data' },
	'7034': { type: 'error', text: 'Error occurred while fetching user list data' },
	'7035': { type: 'error', text: 'Error occurred while adding user privilege data' },
	'7036': { type: 'error', text: 'Error occurred while view user privilege data' },
	'7037': { type: 'error', text: 'Error occurred while updating user privilege data' },
	'7038': { type: 'error', text: 'Error occurred while validateing user data' },
	'7039': { type: 'error', text: 'UserId is required' },
	'7040': { type: 'error', text: 'UserId is not valid' },
	// RNE related error
	'7060': { type: 'error', text: 'Error occurred while getting meeting types.' },

	// Role manage related error
	'7501': { type: 'error', text: 'Error occurred while fetching role data' },
	'7502': { type: 'error', text: 'Error occurred while adding role data' },
	'7503': { type: 'error', text: 'Error occurred while updating role data' },
	'7504': { type: 'error', text: 'Error occurred while updating role\'s privilege data' },
	'7505': { type: 'error', text: 'Error occurred while updating description' },

	// Tasks related erros
	'8001': { type: 'error', text: 'Assignee is required.' },
	'8002': { type: 'error', text: 'Please enter inactive reason.' },
	'8004': { type: 'error', text: 'Task id is not valid or missing.' },
	'8007': { type: 'error', text: 'Inactive Reason length cannot be more than 200.' },
	'8008': { type: 'error', text: 'End Date should be equal or greater than Start Date.' },
	'8010': { type: 'error', text: 'Title is required.' },
	'8011': { type: 'error', text: 'Renewal Year is required.' },
	'8012': { type: 'error', text: 'Assignee is must be greater than zero.' },
	'8013': { type: 'error', text: 'Select valid renewal year.' },
	'8015': { type: 'error', text: 'Inactive task cannot be updated.' },
	'8016': { type: 'error', text: 'Task is already done.' },
	'8017': { type: 'error', text: 'Do not use this code anywhere else. We are using it to display confirm popup.' },
	'8018': { type: 'error', text: 'Title length cannot be more than 100.' },
	'8020': { type: 'error', text: 'Do not use this code anywhere else. We are using it to display alert popup.' },
	'8021': { type: 'error', text: 'You cannot skip a status. Please move this task through the workflow.' },
	'8022': { type: 'error', text: 'This task cannot be updated from Open to Done, because it is dependent on other task(s) that have not been completed yet.' },
	'8023': { type: 'error', text: 'This task cannot be updated to Upcoming since the start date occurs in the past.' },
	'8024': { type: 'error', text: 'Reminder date should not be past date.' },
	'8025': { type: 'error', text: 'Reminder notification days must be postitive value.' },
	'8026': { type: 'error', text: 'Reminder notification days cannot be greater than reminder date.' },
	'8027': { type: 'error', text: 'AddPeople id is not valid or missing.' },
	'8030': { type: 'error', text: 'Comment is required.' },
	'8101': { type: 'error', text: 'Cannot assign duplicate depends on task.' },
	'8102': { type: 'error', text: 'Cannot add all depends on tasks.' },
	'8103': { type: 'error', text: 'Depends task is out of range.' },
	'8104': { type: 'error', text: 'Task id is not valid or missing.' },
	'8105': { type: 'error', text: 'Please select at least one task.' },
	'8106': { type: 'error', text: 'New depends-on task id is not valid.' },
	'8109': { type: 'error', text: 'A Task dependency does not exists.' },
	'8119': { type: 'error', text: 'Cannot add duplicate watcher.' },
	'8059': { type: 'error', text: 'Error occured while getting ballot Details.' },
	// Member Management related errors
	'8120': { type: 'error', text: 'MOU Contact Code is required.' },
	'8121': { type: 'error', text: 'Please enter reason for update.' },
	'8122': { type: 'error', text: 'Graduation Date is required.' },
	'8123': { type: 'error', text: 'Please select a record.' },
	'8124': { type: 'error', text: 'Historical Reason is required.' },
	'8125': { type: 'error', text: 'Graduation Date should be future date.' },
	'8126': { type: 'error', text: 'Classification is required.' },
	'8127': { type: 'error', text: 'No Vote Reason is required.' },
	'8128': { type: 'error', text: 'Committee Id is required.' },
	'8129': { type: 'error', text: 'Committee Designation is required.' },
	'8130': { type: 'error', text: 'Committee Join Date is required.' },
	'8131': { type: 'error', text: 'Inactive Reason is required.' },
	'8132': { type: 'error', text: 'Officer Appointed Date is required.' },
	'8133': { type: 'error', text: 'Reason for Update is required.' },
	'8134': { type: 'error', text: 'Main Committee Roster Notes is required.' },
	'8135': { type: 'error', text: 'Account Number is required.' },
	'8136': { type: 'error', text: 'Roster Notes is required.' },
	'8137': { type: 'error', text: 'Please select Operator.' },
	'8138': { type: 'error', text: 'Please select at least one Classification Type.' },
	'8139': { type: 'error', text: 'Please set Balance Rule.' },
	'8140': { type: 'error', text: 'Please select at least one application.' },
	'8141': { type: 'error', text: 'Please select Officer Title.' },
	'8165': { type: 'error', text: 'Error occured while updating member facility info.' },
	'8171': { type: 'info', text: 'This organization currently does not have an Account Number due to incorrect address. Please update the correct address to generate the Organization Account Number' },
	'8172': { type: 'info', text: 'Student member program is disabled for the committee.'},

	// Fee Group related errors
	'8142': { type: 'error', text: 'Fee Group Title is required.' },
	'8143': { type: 'error', text: 'Error occurred while adding fee group data' },
	'8144': { type: 'error', text: 'Error occurred while getting fee group list data' },
	'8145': { type: 'error', text: 'Error occurred while updating fee group data' },
	'8146': { type: 'error', text: 'Error occurred while checking uniq fee group\'s title data' },
	'8147': { type: 'error', text: 'Fee Group Title is required.' },
	'8148': { type: 'error', text: 'Contact Person Name is required.' },
	'8149': { type: 'error', text: 'Fee Group Name already exists.' },
	'8150': { type: 'error', text: 'Error occurred while getting Fee Group Banner details.' },
	'8151': { type: 'error', text: 'Error occurred while getting Fee Group details.' },
	'8152': { type: 'error', text: 'Error occurred while associating Member in  Fee Group.' },
	'8153': { type: 'error', text: 'Error occurred while deleting associated Member in Fee Group.' },
	'8154': { type: 'error', text: 'Error occurred while updating Fee Group status.' },
	'8155': { type: 'error', text: 'Fee Group status is required.' },
	'8156': { type: 'error', text: 'Error occurred while getting Fee Group associated members list data.' },
	'8157': { type: 'error', text: 'No Members associated.' },
	'8158': { type: 'error', text: 'Error occurred while getting Active Fee Group List.' },
	'8159': { type: 'error', text: 'Please select at least one Member record' },
	'8160': { type: 'error', text: 'Error occurred while exporting Fee Group data.' },
	'8161': { type: 'error', text: 'Invoice can\'t be generated as no members are associated with it.' },
	'8162': { type: 'error', text: 'Fee Group is required.' },
	'8163': { type: 'error', text: 'Honorary status is required.' },
	'8164': { type: 'error', text: 'Committee Pay status is required.' },
	'8166': { type: 'error', text: 'Error occurred while getting Membership Type Information.' },
	'8167': { type: 'error', text: "Country is required" },
	'8168': { type: 'error', text: 'Error occured while updating organizational details.' },


	// Info messages 9000 to 9200
	'9000': { type: 'info', text: 'No Task in Upcoming Status' },
	'9001': { type: 'info', text: 'No Task in Open Status' },
	'9002': { type: 'info', text: 'No Task in Done Status' },
	'9003': { type: 'info', text: 'Are you sure you want to break the dependency?' },
	'9004': { type: 'info', text: 'Do you want to break the dependency with this Task(s) and update the record?' },
	'9005': { type: 'info', text: 'Updating Status shall break the dependency with this Task(s). Do you want to update the status?' },
	'9006': { type: 'info', text: 'Do you want to clone the attachments?' },
	'9007': { type: 'info', text: 'Do you want to clone the Task?' },
	'9008': { type: 'info', text: 'Are you sure you want to delete this log?' },
	'9009': { type: 'info', text: 'Are you sure you want to remove this document?' },
	'9010': { type: 'info', text: 'No results found.' },
	'9011': { type: 'info', text: 'No search results found. Try refining your search using the advanced filter.' },
	'9012': { type: 'info', text: 'No logs.' },
	'9013': { type: 'info', text: 'No Search Results Found.' },
	'9014': { type: 'info', text: 'There are no records found! Do you still want to continue downloading an empty file?' },
	'9015': { type: 'info', text: 'No communication logs.' },
	'9016': { type: 'info', text: 'Please select an appropriate option carefully before proceeding with this action.' },
	'9017': { type: 'info', text: 'Please select an option.' },
	'9018': { type: 'info', text: 'Updating Membership Type will remove the association of this Member with @Organization.' },
	'9019': { type: 'info', text: 'Update account status of Organization to Historical and remove association' },
	'9020': { type: 'info', text: 'Let account status of Organization remain Active and remove association' },
	'9021': { type: 'info', text: 'Are you sure you want to perform this action for a Deceased member?' },
	'9022': { type: 'info', text: 'This @membertype is already associated with @Organization.' },
	'9023': { type: 'info', text: 'Update account status of @Organization to Historical and remove association' },
	'9024': { type: 'info', text: 'Let account status of @Organization remain Active' },
	'9025': { type: 'info', text: 'Removing the association with Organization will update the Account Status of this Member to Historical.' },
	'9026': { type: 'info', text: 'The Organization selected has an active association with a @membertype. Please remove the active association by navigating to the @OrganizationLink page and then try again.' },
	'9027': { type: 'info', text: 'Update account status of @Organization to Historical' },
	'9028': { type: 'info', text: 'The @membertype Member selected has an active association with an Organization. Please update the association with this Organization by navigating to the @RepresentativeLink page.' },
	'9029': { type: 'info', text: 'Membership Type of @SelectedMemberName will be updated from @PreviousMembership to @Representative. Do you want to proceed?' },
	'9030': { type: 'info', text: 'The @membertype Member selected has an active association with an Organization. Clicking "Yes" button will result in no @membertype assignment for this organization and will update the Account status of linked @membertype to Historical. In order to update the association with this @membertype please navigate to the @RepresentativeLink page. Do you want to proceed?' },
	'9031': { type: 'info', text: 'Membership Type of @SelectedMemberName will be updated from @PreviousMembership to @Representative. Do you want to proceed? Removing the association will update the account status of @CurrentRep to Historical.' },
	'9032': { type: 'info', text: 'Removing the association with @membertype will result in no @membertype assignment for this organization and will update the Account status of @membertype to Historical. Do you want to proceed?' },
	'9033': { type: 'info', text: 'Account status can only be updated to Active when the @membertype account is associated with Organization. Do you want to associate an Organizational account and activate the @membertype account?' },
	'9034': { type: 'info', text: '@Organization was the Organization of this @membertype. Do you want to activate the @membertype account as well as Organizational account?' },
	'9035': { type: 'info', text: 'This Organization is already associated with @Representative.' },
	'9036': { type: 'info', text: 'Update account status of Organization as well as @membertype to Active' },
	'9037': { type: 'info', text: 'Associate another @membertype and remove association with @Representative' },
	'9038': { type: 'info', text: 'Update account status of Organization to active and remove association' },
	'9039': { type: 'info', text: 'No @membertype Account is linked to this Organization.' },
	'9040': { type: 'info', text: 'Update account status of Organization to active without associating @membertype' },
	'9041': { type: 'info', text: 'Update account status of Organization to active and associate another @membertype' },
	'9042': { type: 'info', text: 'The @membertype Member selected has an active association with an Organization. Clicking "Yes" button will update the account status of this Organization to Active with no @membertype associated with it. In order to update the association with this @membertype please navigate to the @RepresentativeLink page. Do you want to proceed?' },
	'9043': { type: 'info', text: 'You are updating Account Status from Historical to Active. Please enter a new Graduation Date.' },
	'9044': { type: 'info', text: `This action will change this member's classification at the Main Committee to PRODUCER. Do you want to proceed?` },
	'9045': { type: 'info', text: 'Are you sure you want to cancel?' },
	'9046': { type: 'info', text: 'Please deselect the Main Committee to deselect a Subcommittee.' },
	'9047': { type: 'info', text: 'Please deselect the Subcommittee to deselect a Main Committee.' },
	'9048': { type: 'info', text: 'Are you sure you want to delete this record? If yes, please provide reason for update.' },
	'9049': { type: 'info', text: 'No Meeting Sequence available.' },
	'9050': { type: 'info', text: 'No Meeting Dates available.' },
	'9051': { type: 'info', text: 'No records to display.' },
	'9052': { type: 'info', text: 'The system will add Member to <Main Committee Designation>. Do you want to proceed?' },
	'9053': { type: 'info', text: 'The system will update the Member Classification to Producer in <Main Committee Designation>. Do you want to proceed?' },
	'9054': { type: 'info', text: 'x - This classification is used in the Balance Rule. To delink this classification, update Balance Rule.' },
	'9055': { type: 'info', text: "y - This classification is assigned to a member. To delink this classification, update the member's classification." },
	'9056': { type: 'info', text: 'z - This classification is Applicable to all @CommitteeType Committees. Delinking requires Bylaws revision and COTCO approval.' },
	'9057': { type: 'info', text: 'Are you sure you want to delete this Committee?' },
	'9058': { type: 'info', text: 'This action will remove the association of Officer Title from Committee. Do you want to continue?' },
	'9059': { type: 'info', text: 'The changes you have made will not be saved. Do you want to leave?' },
	'9060': { type: 'info', text: 'This action is not going to automatically remove the suppression state in Committees created under it.' },
	'9061': { type: 'info', text: 'System will update the Vote Assigned date and Classification Assigned date to Committee Join Date you just entered. Do you want to continue?' },
	'9062': { type: 'info', text: 'System will update the Classification Assigned date to Committee Join Date you just entered. Do you want to continue?' },
	'9063': { type: 'info', text: 'System will update the Vote Assigned date to Committee Join Date you just entered. Do you want to continue?' },
	'9064': { type: 'info', text: 'Select which committees this member has been approved as an affiliate by the respective executive subcommittee per the ASTM Regulations.' },
	'9065': { type: 'info', text: 'The member will be marked as Inactive in those committees in which the member is not approved as an affiliate.' },
	'9066': { type: 'info', text: 'This Member does not have any active committee affiliation.' },
	'9067': { type: 'info', text: 'The system will add Member to <Main Committee Designation> as a Producer. Do you want to proceed?' },
	'9068': { type: 'info', text: 'No active members exists.' },
	'9069': { type: 'info', text: 'This action will reset the settings to default settings which are configured in Rules and Exceptions application. Do you want to continue?' },
	'9070': { type: 'info', text: 'This action will inactivate <Next Level Titles> and rosters under it. Do you want to proceed?' },
	'9071': { type: 'info', text: 'Committee cannot be reactivated as the Committee under which this Committee is created is inactive. Please activate the Committee <Previous Level Committee Designation> and then try again.' },
	'9072': { type: 'info', text: 'Inactivating this Fee Group shall remove the association with [X] Members. Are you sure you want to update the record?' },
	'9073': { type: 'error', text: 'Error occured while getting work item details.' },
	'9074': { type: 'error', text: 'Please enter a Work Item Number' },
	'9075': { type: 'error', text: 'Work Item not found' },
	'9076': { type: 'error', text: 'Error occured while getting Standard Type List.' },
	'9077': { type: 'error', text: 'Error occured while updating work item details.' },
	'9080': { type: 'error', text: 'You must select a Target Ballot Date for this Work Item.' },
	'9081': { type: 'error', text: 'You must enter your Work Item Rationale.' },
	'9082': { type: 'error', text: 'Please assign a Technical Contact' },
	'9083': { type: 'error', text: 'Please provide Emergency Response' },
	'9084': { type: 'error', text: 'You must enter an Emergency Description.' },
	'9085': { type: 'error', text: 'Please select a value' },
	'9086': { type: 'error', text: 'You must enter your Title for the Work Item.' },
	'9087': { type: 'error', text: 'You must enter your Scope for the Work Item.' },
	'9088': { type: 'error', text: 'You must enter your Work Item Keywords.' },
	'9089': { type: 'error', text: 'Please select a value for the Standard Type' },
	'9090': { type: 'error', text: 'Clicking on confirm will update work item details in MCS.' },
	'9091': { type: 'success', text: 'Changes to the work item saved successfully.' },
	'9092': { type: 'error', text: 'Error occured while getting work Item associated ballot status.' },
	'9093': { type: 'info', text: 'The work item does not have a ballot action associated with it' },
	'9094': { type: 'info', text: 'The work item has a ballot action associated with it' },
	'9095': { type: 'info', text: 'Delete Work Item <designation>' },
	'9096': { type: 'info', text: 'This action will delete the work item from MCS and it will be removed from the web. The associated collaboration area would also get deleted. Do you still want to continue?' },
	'9097': { type: 'info', text: 'This action will suppress the work item and the associated collaboration area from the web. It will remain in MCS. Do you still want to continue?' },
	'9098': { type: 'info', text: 'Reason for Delete' },
	'9099': { type: 'error', text: 'Please provide a reason for deleting the work item' },
	'9100': { type: 'success', text: 'Work Item deleted successfully' },
	'9101': { type: 'error', text: 'Error occured while deleting Work Item.' },
	'9102': { type: 'error', text: 'Error occured while restoring Work Item.' },
	'9103': { type: 'success', text: 'Work Item restored successfully' },
	'9104': { type: 'error', text: 'Error occured while getting deleted work item list.' },
	'9105': { type: 'error', text: 'Error occured while getting work item status.' },
	'9106': { type: 'error', text: 'Error occured while getting sub committee list.' },
	'9107': { type: 'error', text: 'Please select sponsoring subcommittee.' },
	'9108': { type: 'error', text: 'Work Item not found.' },
	'9109': { type: 'error', text: 'Error occured while getting technical contact list.' },
	'9110': { type: 'error', text: 'Membership Type is required.' },
	'9111': { type: 'error', text: 'Please select the Main Committee.' },
	'9112': { type: 'error', text: 'Please select the Primary Activity of Member.' },
	'9113': { type: 'error', text: 'Organization Name is required.' },
	'9114': { type: 'error', text: 'Products or Services is required.' },
	'9115': { type: 'error', text: 'Consulting Firm is required.' },
	'9116': { type: 'info', text: 'A consultant retained by an organization, whereby the arrangement includes representing it on an ASTM committee or subcommittee.' },
	'9117': { type: 'info', text: 'A consultant retained by multiple organizations, engaged in the same business activity or different business activities.' },
	'9118': { type: 'error', text: 'University/College/Institution is required.' },
	'9119': { type: 'info', text: 'Does the Organization you represent primarily produce or sell products, materials, systems or services within the scope of the selected committee?' },
	'9120': { type: 'error', text: 'Organization you represent produce/sell is required.' },
	'9121': { type: 'error', text: 'Business activities of the organization you represent is required.' },
	'9122': { type: 'info', text: 'Do any of the organizations you represent primarily produce or sell products, materials, systems or services within the scope of the selected Committee?' },
	'9123': { type: 'error', text: 'Government Agency Name is required.' },
	'9124': { type: 'error', text: 'Nature of your interest is required.' },
	'9125': { type: 'error', text: 'Affiliation is required.' },
	'9126': { type: 'error', text: 'Consumer Advocacy Group Name is required.' },
	'9127': { type: 'error', text: 'Advocacy Group Contact Email is required.' },
	'9128': { type: 'info', text: 'What is the nature of your Consumer interest in the standards developed by the Committees you seek to join?' },
	'9129': { type: 'error', text: 'Organization Account Number is required.' },
	'9130': { type: 'error', text: 'Order Number is required.' },
	'9131': { type: 'error', text: 'Order Date is required.' },
	'9132': { type: 'error', text: 'Error occured while getting demographic details and order details.' },
	'9133': { type: 'error', text: 'Error occured while getting demographic details.' },
	'9134': { type: 'error', text: 'No data found for the entered account number.' },
	'9135': { type: 'error', text: 'First Name is required.' },
	'9136': { type: 'error', text: 'Last Name is required.' },
	'9137': { type: 'error', text: 'Address Line 1 is required.' },
	'9138': { type: 'error', text: 'City is required.' },
	'9139': { type: 'error', text: 'Country is required.' },
	'9140': { type: 'error', text: 'Postal Code is required.' },
	'9141': { type: 'error', text: 'Phone is required.' },
	'9142': { type: 'error', text: 'Invalid Phone.' },
	'9143': { type: 'error', text: 'Email is required.' },
	'9144': { type: 'error', text: 'Organization Name is required.' },
	'9145': { type: 'error', text: 'State/Province is required.' },
	'9146': { type: 'error', text: 'Free Volume is required.' },
	'9147': { type: 'error', text: 'Volume Format is required.' },
	'9148': { type: 'error', text: 'Error occured while getting Free Volume.' },
	'9149': { type: 'error', text: 'Please enter a member account number.' },
	'9150': { type: 'error', text: 'Please enter a valid member account number.' },
	'9151': { type: 'error', text: 'Error occured while getting member account number details.' },
	'9152': { type: 'info', text: 'Are you sure you want to skip this step?' },
	'9153': { type: 'error', text: 'Name of your College/University is required.' },
	'9154': { type: 'error', text: 'Anticipated Graduation Date is required.' },
	'9155': { type: 'error', text: 'Month is required.' },
	'9156': { type: 'error', text: 'Error occured while getting ballot item list.' },
	'9157': { type: 'error', text: 'Organization Account Number can not be the same as Representative Account Number.' },
	'9158': { type: 'error', text: 'Error occured while getting View Vote History.' },
	'9159': { type: 'error', text: 'Member Fee status Should not be Paid!.' },
	'9160': { type: 'error', text: 'Account Number/Organization Account Number already exists.' },
	'9161': { type: 'error', text: 'Please add this member to the committees in order to update the Membership type to Participating.' },
	'9162': { type: 'error', text: 'This committee has active standards associated with it. Members will not be able to submit work items or ballot items for existing standards or new standards for this committee(/sub). Do you want to continue?' },
	'9163': { type: 'error', text: 'The same member will be assigned as the <Administrative Assistant OR Staff Manager> in the <Main Committee Designation> and all the subcommittees/sections under it. Are you sure you want to continue?' },
	'9164': { type: 'error', text: 'Address Line 2 is required.' },
	'9165': { type: 'error', text: 'Error occured while creating new member.' },
	'9166': { type: 'error', text: 'The selected members will be assigned as the Staff Manager, Administrative Assistant in the <Main Committee Designation> and all the subcommittees/sections under it. Are you sure you want to continue?' },
	'9167': { type: 'success', text: 'Membership account created successfully' },
	'9168': { type: 'error', text: 'Error occured while getting order details.' },
	'9169': { type: 'success', text: 'Membership account renewed successfully.' },
	'9170': { type: 'success', text: 'Membership account reinstated successfully.' },
	'9171': { type: 'success', text: 'Membership account upgraded successfully.' },
	'9172': { type: 'info', text: 'The Organization Name & Address combination is already in the system for @Organization. The changes cannot be saved. Please verify the information is correct to avoid duplicate records.' },
	'9173': { type: 'info', text: 'This organization already exists. Do you want to associate this member to the existing ORG?' },
	'9174': { type: 'error', text: 'The work Item is restored in membership but failed to restore the associated collaboration area due to non-responsive API action. The Work Item and Collaboration Area restore log has been captured for manual correction by the Help Desk. No further action is required. You can close the application. ' },
	'9175': { type: 'error', text: 'The work Item is updated in membership but failed to update the associated collaboration area due to non-responsive API action. The Work Item and Collaboration Area update log has been captured for manual correction by the Help Desk. No further action is required. You can close the application.' },
	'9176': { type: 'error', text: 'The work Item is deleted in membership but failed to delete the associated collaboration area due to non-responsive API action. The Work Item and Collaboration Area delete log has been captured for manual correction by the Help Desk. No further action is required. You can close the application.' },
	'9177': { type: 'error', text: 'We cannot verify the address you have provided. Do you want to proceed with the address you have entered?' },
	'9178': { type: 'error', text: 'We have slightly modified the address entered. If correct, please use the suggested address to ensure accurate delivery.'},
	'9179': { type: 'error', text: 'No search results found. Try refining your search using the advanced filter.'},
	'9180': { type: 'error', text: 'Please enter the active college/university account number.'},
	'9181': { type: 'error', text: 'Please enter the active organization account number.'},
	'9182': { type: 'error', text: 'Please enter the active college/university account details.'},
	'9183': { type: 'error', text: 'Please enter the active organization account details.'},
	'9184': { type: 'error', text: 'There are currently active student members associated with the committee. Please manually update their status to inactive if necessary.'},
	'9185': { type: 'info', text: 'Student Member participation in committee'},
	'9186': { type: 'info', text: 'Student member program is disabled for the committee.'},

	// Membership Renewal related errors
	'9500': { type: 'error', text: 'Error occured while getting membership renewal details.' },
	'9501': { type: 'info', text: 'Are you sure you want to schedule the first renewal notice?' },
	'9502': { type: 'success', text: 'Renewal cycle started successfully.' },
	'9503': { type: 'error', text: 'Error occured while starting renewal cycle.' },
	'9504': { type: 'info', text: 'This action will drop all those Members who were supposed to renew their Membership but did not renew. Are you sure you want to continue?' },
	'9505': { type: 'error', text: 'File upload link has been expired.' },
	'9506': { type: 'info', text: 'This action will drop all those Temporary Members who are active for more than 18 months.Are you sure you want to continue?.' },
	'9507': { type: 'error', text: 'Error occured while getting signed url from S3.' },
	'9508': { type: 'success', text: 'Request processed successfully.' },
	'9509': { type: 'error', text: 'Please upload the file with valid records.' },
	'9510': { type: 'error', text: 'Process Failed' },
	'9511': { type: 'info', text: 'This action will replace the existing book volume movement information to be visible to the Members while renewal process. Are you sure you want to continue?' },
	'9512': { type: 'success', text: 'File uploaded successfully' },
	'9513': { type: 'error', text: 'Error occured while getting committee\'s officer' },
	'9514': { type: 'error', text: 'Error occured while getting officer title data' },
	'9515': { type: 'error', text: 'Error occured while getting ballot item submission data' },
	'9516': { type: 'info', text: 'No ballot item submission data.' },
	'9517': { type: 'attention', text: 'Document not found.' },
	'9518': { type: 'error', text: 'This field cannot be empty.' },
	'9519': { type: 'info', text: 'There are active standards under the Subcommittees under it. Please move the standards in order to disband the Committee.' },
	'9520': { type: 'info', text: 'There are active standards under this Subcommittee. Please move the standards in order to disband the Subcommittee.' },
	'9521': { type: 'error', text: 'Please assign a Subcommittee' },
	'9522': { type: 'error', text: '@OfficerTitleName already exists in the committee.' },
	'9523': { type: 'error', text: 'There can be only one Staff Manager/Administrative Assistant/Chair/Sub Committee Chair in a committee.' },
	'9524': { type: 'error', text: '@OfficerTitleName already exists in the committee @committeeDesignationValidation.' },
	'9530': { type: 'error', text: 'Please select at least one option' },
	'9531': { type: 'error', text: 'Error in generating excel files' },
	'9525': { type: 'error', text: 'Error occurred while searching data.' },
	'9526': { type: 'error', text: 'This field is required' },
	'9527': { type: 'error', text: 'Affiliation organization is required.' },
	'9528': { type: 'error', text: 'Organization you represent is required.' },
	'9529': { type: 'error', text: 'Zip/Postal Code is invalid.' },
	'9532': { type: 'info', text: 'Renewal is going to start. Are you sure you want to continue?' },
	'9533': { type: 'success', text: 'Process fee drop started successfully.' },
	'9534': { type: 'success', text: 'Document deleted successfully.' },
	'9535': { type: 'error', text: 'Error is some issue while downloading document.' },
	'9536': { type: 'info', text: 'Are you sure you want to schedule the drop temporary members?' },
	'9537' : {type: 'info', text: 'Are you sure you want to cancel the drop temporary members process?'},
	'9538' : {type: 'info', text: 'Are you sure you want to reschedule the drop temporary members process?'},
	'9539' : {type: 'info', text: 'Are you sure you want to schedule the Process Fee Drop?'},
	'9540' : {type: 'info', text: 'Are you sure you want to cancel the Process Fee Drop?'},
	'9541' : {type: 'info', text: 'Are you sure you want to reschedule the Process Fee Drop?'},

	// Membership Report Error/Info Messages
	'9700': { type: 'info', text: 'The Memberships by Count Report displays the count of members based on their membership type, income type, membership status and their paid status. Select a date and click on the Download button to download the report.' },
	'9701': { type: 'info', text: 'The Membership Revenues by Type displays the revenue from the member based on their membership type. Select a year and click on Download button to download the report.' },
	'9702': { type: 'error', text: 'Report is not available for the selected date, please select a different date.' },
	'9703': { type: 'info', text: "The All Society Member report displays the count of new members and renewals processed from the Web & Customer Relations and displayed under each membership types based on the selected period." },
	'9704': { type: 'info', text: "The prior year list displays the past year reports available for download." },
	'9705': { type: 'error', text: "There seems to be a momentary issue. Please refresh or try again after sometime." },
	'9706': { type: 'error', text: "Report not available for selected date. Please select a different date." },
	'9707': { type: 'error', text: "Report is not available for selected year. Please select a different year." },
	'9708': { type: 'info', text: "The Monthly Statistics report displays the count of memberships at the First (Start) of the month, the End of the month and Net change in membership. Select the month and click on Download button to download the report." },
	'9709': { type: 'error', text: "Report is not available for selected Date (or) Year. Please select a different Date (or) Year." },
	'9710': { type: 'error', text: "Data Not Available for the selected Dates" },
	'9711': { type: 'error', text: "Data Not Available for the selected Year" },
	'9712': { type: 'error', text: 'Error occurred while generating excel files' },

	// Member Facility Error Messages
	'9800': { type: 'error', text: "Error occurred while account details" },
	'9801': { type: 'error', text: 'Error occurred while downloading template' },
	'9802': { type: 'error', text: 'Error occurred while downloading list' },
	'9803': { type: 'info', text: 'Are you sure you want to schedule the second renewal notice?' },
	'9806': { type: 'error', text: 'There is some momentary issue while downloading fee drop report' },
	'9808': { type: 'error', text: 'There is some momentary issue while downloading auto renewal member list' },
	'9809': { type: 'error', text: 'Error occurred while scheduling renewal process' },
	'9810': { type: 'info', text: 'Are you sure you want to cancel the First Renewal batch job?' },
	'9811': { type: 'info', text: 'Are you sure you want to reschedule the First Renewal?' },
	'9812': { type: 'info', text: 'Are you sure you want to cancel the Second Renewal batch job?' },
	'9813': { type: 'info', text: 'Are you sure you want to reschedule the Second Renewal?' },

	// Meeting Report Error Messages
	'9850': { type: 'error', text: "There is some momentary issue while attendee details" },

	// Membership Exception Report Error Messages
	'9890': { type: 'error', text: "Error occurred while getting membership exception list" },
	'9891': { type: 'error', text: "Error occurred while getting membership exception header list" },
	'9892': { type: 'error', text: "Error occurred while updating status" },
	'9893': { type: 'success', text: "Membership Exception status updated successfully." },

	// BOS Volume Location
	'9894': { type: 'error', text: "Error occurred while getting BOS volume location list." },
	'9895': { type: 'error', text: "Error occurred while updating BOS volume location." },

	// Manual Order
	'9896': { type: 'error', text: "Required field" },
	'9897': { type: 'error', text: "The Member you are trying to reinstate is deceased. It is not allowed to reinstate the deceased Member in the system." },
	'9898' : {type: 'error', text: "The account number does not exist in the system. You can add the Organization details manually."},
	'9899': { type: 'error', text: "Please choose a different organization as the member is already associated with the entered organization account number" },
	'9900' : {type: 'error', text: "Please choose a different organization as the member is already associated with the entered organization"},

	//Manage Alert
	'9901': { type: 'info', text: 'Are you sure you want to schedule the alert message for members?' },
	'9902': { type: 'info', text: 'Are you sure you want to cancel the scheduled alert message for members?' },
	'9903': { type: 'error', text: 'Alert Message is required' },
	'9904': { type: 'error', text: 'Date & Time is required' },
	'9905': { type: 'error', text: 'Error occurred while getting alerts data' },
	'9906': { type: 'error', text: 'Error occurred while adding alert message' },
	'9907': { type: 'success', text: 'Alert Message has been scheduled successfully.' },
	'9908': { type: 'success', text: 'Alert Message details have been updated successfully.' },
	'9909': { type: 'attention', text: 'The Update Alert Message will not be saved as no edits were made.' },
	'9910': { type: 'error', text: 'Error occurred while updating alert message' },
	'9911': { type: 'error', text: 'Error occurred while cancelling alert message' },
	'9912': { type: 'error', text: 'End Date & Time is required' },

	//Tableau Reports
	'10001' : { type: 'success', text: 'Tableau signin validated.' },
	'10002' : { type: 'error', text: 'Tableau signin failed.' },
	'10003' : { type: 'success', text: 'Tableau users list successfully fetched.' },
	'10004' : { type: 'error', text: 'Tableau users list failed to fetch.' },
	'10005' : { type: 'success', text: 'Tableau user has permission for reports.' },
	'10006' : { type: 'error', text: 'Tableau user has no permission for reports.' },
	'10007' : { type: 'success', text: 'Tableau reports successfully fetched.' },
	'10008' : { type: 'error', text: 'Tableau reports failed to fetch.' },
	'10009' : { type: 'error', text: 'Error occurred while getting report data.' },

}

export default en;
