import React, { Component } from "react";
import { Confirm, Grid, Modal, Form, Icon } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form';
import * as common from 'helpers/util-common';
import TextArea from 'shared-components/TextArea'
import RenderRadioField from 'shared-components/RadioBox'
import RenderSelect from 'shared-components/Select';
import DateTimeForm from 'shared-components/DatePicker';
import RenderInput from 'shared-components/Input';
import DropdownMultipleSelection from 'shared-components/MultiSelectDropDown';
import * as _func from './function';
import _ from "lodash";
import CustomAlertModal from 'shared-components/CustomAlertModal';
import ConsumerDetails from "../../../../admin/manual-order/components/consumer-details";
import ConsultantDetails from "../../../../admin/manual-order/components/consultant-details";
import { primaryActivityModelConstant } from "../../../../admin/manual-order/manual-order.model";
import { cacheManager, getMessage } from "../../../../../helpers/util-common";
import { membershipTypeId, minDateForInputDateFields } from "../../../../../models/common.models";

class AddMemberCommitteeModal extends Component {
    state = {
        selectedCommittees: [],
        previousSelectedCommittees: [],
        committeeDesignationNameList: [],
        selectedVoteStatus: 'false',
        noVoteReasons: [],
        selectedVote: this.props.initialValues?.MemberTypeId === 17 && JSON.parse(cacheManager.getItem("featureFlag"))?.StudentCommitteeManagement ? '5': '1', // For Student Member default value would be Non Voter
        commonClassification: [{ key: 5, text: 'Unclassified', value: 5 }],
        commonOfficerTitle: [],
        maxDate: common.formatDateToMMDDYYYY(),
        minDate: _(this.props.initialValues).get('EstablishmentDate') ? common.formatDateToMMDDYYYY(this.props.initialValues.EstablishmentDate) : common.formatDateToMMDDYYYY(minDateForInputDateFields),
        dateJoined: common.formatDateToMMDDYYYY(),
        classificationDate: common.formatDateToMMDDYYYY(),
        voteAssignedDate: common.formatDateToMMDDYYYY(),
        officerTitleName: "",
        isShowAdministrativeAssistantPopup: false,
        committeeDesignationValidation: '',
        isShowAdministrativeAssistantOrStaffManagerPopup: false,
        primaryActivityDropdown: [],
        masterPrimaryActivityDropdown: [],
        isOrgAssociated: false,
        primaryActivityOfMember:  this.props.initialValues?.MemberTypeId === 17 && JSON.parse(cacheManager.getItem("featureFlag"))?.StudentCommitteeManagement? '10': -1, // For Student Member default value would be Other
        isPrimaryActivityApplicable: false,
        customOverrideMessage:undefined,
        flagFeature: JSON.parse(cacheManager.getItem("featureFlag"))
    }
    componentDidMount() {
        _func.setClassInstance(this);
        _func.loadInitialData();
        this.props.change('dateJoined', common.formatDateToMMDDYYYY());
        this.props.change('voteDate', common.formatDateToMMDDYYYY());
        this.props.change('officerAppointedDate', common.formatDateToMMYYYY());
        this.props.change('ClassificationDate', common.formatDateToMMDDYYYY());
        if (this.props.initialValues?.MemberTypeId === 17 && JSON.parse(cacheManager.getItem("featureFlag"))?.StudentCommitteeManagement) {
          this.props.change("noVoteReason", 5);
        } else {
          this.props.change("noVoteReason", 1);
        }

        if (this.state.commonOfficerTitle && this.state.commonOfficerTitle.length > 0) {
            let defaultCommitteeId = this.state.commonClassification.filter(item => item.text.toLowerCase() === 'unclassified')[0].value;
            this.props.change('classificationTypeId', defaultCommitteeId)
        } else {
            this.props.change('classificationTypeId', 5)
        }
    }

    getOfficerTitleChangeHandler = (e, value) => {
        const { commonOfficerTitle } = this.state;
        if (commonOfficerTitle && commonOfficerTitle.length > 0) {
            let result = commonOfficerTitle.filter((filterResult) => {
                return filterResult.value === value;
            });
            if (result && result.length > 0) {
                this.setState({ officerTitleName: _.get(result[0], 'text') })
            }
        }
    }

    render() {
        const { close, handleSubmit, submitting, messageCodes } = this.props;
        const { committeeDesignationNameList, selectedVoteStatus, noVoteReasons, selectedVote, commonClassification, commonOfficerTitle, maxDate, minDate, dateJoined, isShowAdministrativeAssistantPopup, committeeDesignationValidation, isShowAdministrativeAssistantOrStaffManagerPopup, officerTitleName, committeeName, primaryActivityDropdown, isOrgAssociated, primaryActivityOfMember, isPrimaryActivityApplicable, customOverrideMessage, selectedCommittees, flagFeature } = this.state
        return (
            <Modal open='true' className="tiny add-member-popup" data-testid="addMemberCommitteeModal">
                <Form size='large' onSubmit={handleSubmit(_func.checkCommitteeOfficer)} noValidate >
                    <Modal.Header>Add Member to a Committee <i class="close" onClick={close}>&#x2716;</i></Modal.Header>
                    <Modal.Content scrolling>
                        <Modal.Description>
                            <Grid columns='equal'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Field
                                            name='çommitteeDesignation'
                                            component={DropdownMultipleSelection}
                                            onChange={_func.handleCommitteeChange}
                                            label='Committee Designation'
                                            required={true}
                                            placeholder="Search by Committee Designation"
                                            options={_func.formatCommitteeDesignationList(committeeDesignationNameList)}
                                            isMultiple={true}
                                            isSearch={true}
                                            showCustomError={true}
                                            customOverrideMessage={customOverrideMessage}
                                            onSearchChange={(data)=>{
                                                if (this.props.initialValues?.MemberTypeId === 17 && flagFeature?.StudentCommitteeManagement) {
                                                  let tCount = data.options.filter((item) => item.text.toLowerCase().startsWith(data.searchQuery.toLowerCase()))?.length || 0;
                                                  let count = this.props.committeeList.filter((item) => !selectedCommittees?.some((item1) => item1 === item.CommitteeId)).filter((item) => !item.IsStudentEnabled && item.Title.toLowerCase().startsWith(data.searchQuery.toLowerCase()))?.length || 0;
                                                  if (tCount === 0 && count > 0) {
                                                    this.setState({ customOverrideMessage: getMessage(messageCodes, "8172.text") });
                                                  } else {
                                                    this.setState({ customOverrideMessage: undefined });
                                                  }
                                                }
                                            }}
                                            customSearchHandler={(options, value) =>options.filter(item => item.text.toLowerCase().startsWith(value.toLowerCase()))}
                                        />

                                    </Grid.Column>
                                    <Grid.Column>
                                        <Field name="dateJoined"
                                            component={DateTimeForm} label="Date Joined"
                                            showTime={false}
                                            required='true'
                                            initialDate={maxDate}
                                            maxDate={maxDate}
                                            minDate={minDate}
                                            normalize={common.dateMasking}
                                            onChange={_func.handleChangeDateJoined}
                                            readOnly={false}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>
                                        <Field name="classificationTypeId" type="text"
                                            component={RenderSelect} label="Classification"
                                            options={commonClassification}
                                            disabled={this.props.initialValues?.MemberTypeId === 17 && flagFeature?.StudentCommitteeManagement}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Field name="ClassificationDate"
                                            component={DateTimeForm} label="Classification Assigned Date"
                                            showTime={false}
                                            required={true}
                                            normalize={common.dateMasking}
                                            maxDate={maxDate}
                                            minDate={dateJoined}
                                            onChange={_func.handleChangeClassificationDate}
                                            readOnly={false}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>

                                    <Grid.Column>
                                        <label className="label"> <i aria-hidden="true" class="asterisk  icon"></i> Vote</label>
                                        <div className="radioWrap" >
                                            <Field name="voteStatus"
                                                component={RenderRadioField}
                                                className="mr10"
                                                type="radio" onChange={_func.onChangeVoteStatus}
                                                options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]}
                                                selectedValue={selectedVoteStatus}
                                                isDisabled={this.props.initialValues?.MemberTypeId === 17 && flagFeature?.StudentCommitteeManagement}
                                            />
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column>
                                        {selectedVoteStatus === 'false' && <Field name="noVoteReason" type="text"
                                            component={RenderSelect} label="No Vote Reason"
                                            required={selectedVoteStatus === 'false'}
                                            defaultValue={selectedVote}
                                            options={noVoteReasons}
                                            onChange={_func.changeVoteStatusHandler}
                                            disabled={this.props.initialValues?.MemberTypeId === 17 && flagFeature?.StudentCommitteeManagement}
                                        />}
                                        {selectedVoteStatus === 'true' && false &&
                                            <Field name="voteDate"
                                                component={DateTimeForm} label="Vote Date"
                                                showTime={false}
                                                required={selectedVoteStatus === 'true'}
                                                normalize={common.dateMasking}
                                                maxDate={maxDate}
                                                minDate={dateJoined}
                                                value={maxDate}
                                                onChange={this.validateStartDate}
                                                readOnly={false}
                                            />
                                        }
                                        {selectedVoteStatus === 'true' && <div style={{ height: '60px' }}>&nbsp;</div>}
                                    </Grid.Column>

                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Field name="voteDate"
                                            component={DateTimeForm} label="Vote Assigned Date"
                                            showTime={false}
                                            required={true}
                                            normalize={common.dateMasking}
                                            maxDate={maxDate}
                                            minDate={dateJoined}
                                            onChange={_func.handleChangeVoteAssignedDate}
                                            value={maxDate}
                                            readOnly={false}
                                        />

                                    </Grid.Column>
                                    <Grid.Column><div style={{ height: '60px' }}>&nbsp;</div></Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Field
                                            name='officerTitle'
                                            type="text"
                                            component={DropdownMultipleSelection}
                                            label="Officer Title"
                                            value={officerTitleName}
                                            onChange={(e, value) => this.getOfficerTitleChangeHandler(e, value)}
                                            isSearch={true}
                                            placeholder="Search by Officer Title"
                                            isMultiple={false}
                                            options={commonOfficerTitle}
                                            disabled={this.props.initialValues?.MemberTypeId === 17 && flagFeature?.StudentCommitteeManagement}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        {(officerTitleName !== 'Select' && officerTitleName != '') &&
                                            <Field name='officerAppointedDate'
                                                label='Officer Appointed Date'
                                                required={true} placeholder='MM/YYYY'
                                                component={RenderInput}
                                                normalize={common.monthYearDateMask}
                                            />
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                                {isPrimaryActivityApplicable &&
                                    <>

                                        <Grid.Row>
                                            <Grid.Column>
                                                <Field
                                                    name="primaryActivityOfMember"
                                                    label="Primary Activity of Member "
                                                    component={RenderSelect}
                                                    required
                                                    options={primaryActivityDropdown}
                                                    onChange={_func.onChangeCommitteeDetails}
                                                    disabled={this.props.initialValues?.MemberTypeId === 17 && flagFeature?.StudentCommitteeManagement}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        {isOrgAssociated ?
                                            <Grid.Row>
                                                <Grid.Column>
                                                    {(primaryActivityOfMember === primaryActivityModelConstant.manufacturerOfProductService ||
                                                        primaryActivityOfMember === primaryActivityModelConstant.salesDistributorOfMaterialProductService ||
                                                        primaryActivityOfMember === primaryActivityModelConstant.userPurchaserOfProductService ||
                                                        primaryActivityOfMember === primaryActivityModelConstant.testingOfProductService) ? <Field
                                                        name='describeProducts'
                                                        label='Describe your Products or Services'
                                                        placeholder="Indicate the relevance of your products/services to the committee."
                                                        component={TextArea}
                                                        required={true}
                                                        maxLength={255}
                                                        isCounterVisible={true}
                                                        onChange={_func.onChangeCommonForm}
                                                    /> :
                                                        (primaryActivityOfMember === primaryActivityModelConstant.governmentAgency ||
                                                            primaryActivityOfMember === primaryActivityModelConstant.academia ||
                                                            primaryActivityOfMember === primaryActivityModelConstant.other) ?
                                                            <Field
                                                                name='natureOfInterest'
                                                                label='What is the nature of your interest in the standards developed by the Committees you seek to join?'
                                                                component={TextArea}
                                                                placeholder='Click here to type'
                                                                required={true}
                                                                onChange={_func.onChangeCommonForm}
                                                                disabled={this.props.initialValues?.MemberTypeId === 17 && flagFeature?.StudentCommitteeManagement}
                                                            /> :
                                                            (primaryActivityOfMember === primaryActivityModelConstant.consumer ||
                                                                primaryActivityOfMember === primaryActivityModelConstant.consumerAdvocacyGroup) ?
                                                                <Field
                                                                    name='natureOfInterest'
                                                                    label={getMessage(messageCodes, '9128.text')}
                                                                    component={TextArea}
                                                                    placeholder='Click here to type'
                                                                    required={true}
                                                                    onChange={_func.onChangeCommonForm}
                                                                /> :
                                                                (primaryActivityOfMember === primaryActivityModelConstant.consultant ||
                                                                    primaryActivityOfMember === primaryActivityModelConstant.consultingFirm) &&
                                                                <ConsultantDetails ref='ConsultantDetails' {...this.props} />
                                                    }
                                                </Grid.Column>
                                            </Grid.Row> :
                                            <Grid.Row>
                                                <Grid.Column>
                                                    {primaryActivityOfMember === primaryActivityModelConstant.consumer ?
                                                        <ConsumerDetails
                                                            ref='ConsumerDetails'
                                                            {...this.props} />
                                                        :
                                                        primaryActivityOfMember === primaryActivityModelConstant.consultant ?
                                                            <ConsultantDetails ref='ConsultantDetails' {...this.props} />
                                                            :
                                                            primaryActivityOfMember === primaryActivityModelConstant.other &&
                                                            <Field
                                                                name='natureOfInterest'
                                                                label='What is the nature of your interest in the standards developed by the Committees you seek to join?'
                                                                component={TextArea}
                                                                required
                                                                onChange={_func.onChangeCommonForm}
                                                                disabled={this.props.initialValues?.MemberTypeId === membershipTypeId.Student && flagFeature?.StudentCommitteeManagement}
                                                            />
                                                    }
                                                </Grid.Column>
                                            </Grid.Row>
                                        }
                                    </>
                                }
                                <Grid.Row>
                                    <Grid.Column>
                                        <Field name="rosterNotes" type="text"
                                            component={TextArea} label="Roster Notes"
                                            maxLength="1000" placeholder="Type here.." />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Field component={TextArea} type='text' label='Reason for update'
                                            name='UpdateReason' required={true} maxLength='200'
                                            placeholder="Please enter reason for update"
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                            </Grid>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <div>
                            <button className="ui button primary" type="submit" disabled={submitting} >Save</button>
                            <button className="ui button cancel" onClick={close} >Cancel</button>
                        </div>
                        <Confirm className="confirm-box"
                            data-testid="confirmBox"
                            open={isShowAdministrativeAssistantOrStaffManagerPopup}
                            content={common.getMessage(messageCodes, '9163.text').replace('<Administrative Assistant OR Staff Manager>', officerTitleName).replace('<Main Committee Designation>', this.state.committeeName)}
                            onCancel={_func.cancelAdministrativeAssistantOrStaffManagerPopup}
                            onConfirm={_func.confirmAdministrativeAssistantOrStaffManagerPopup}
                            size="tiny"
                            cancelButton="No"
                            confirmButton="Yes"
                        />
                        {isShowAdministrativeAssistantPopup &&
                            <CustomAlertModal content={common.getMessage(messageCodes, '9524.text').replace('@OfficerTitleName', this.state.officerTitleName).replace('@committeeDesignationValidation', this.state.committeeDesignationValidation)} handleClick={_func.handleClickOk} />
                        }
                    </Modal.Actions>
                </Form>
            </Modal >
        )
    }
}
export default (reduxForm({
    form: 'addMemberCommitteeModal',
    validate: _func.validateHandler
})(AddMemberCommitteeModal));
