import { getMessage } from 'helpers/util-common';
import { AccountStatusOptions, ActiveMemberStatusId, DeceasedHistoricalReasonId, membershipTypeJSON, MembershipTypes } from '../../members.model';

let self = {};
let activeStatusId = AccountStatusOptions[0].value;
let historicalStatusId = AccountStatusOptions[1].value;
let isOrgStatusHistorical = false;
let selectedOrgNumber = '';
let graduationDate = '';
let currentAction = 0; // 1 for deceased popup, 2 for associate org modal

export const setClassInstance = (instance) => {
    self = instance;
    graduationDate = '';
    selectedOrgNumber = '';
    currentAction = 0;
}

export const onUpdateAccountStatus = (e, value) => {
    const { initialValues, messageCodes } = self.props;
    selectedOrgNumber = '';

    // Ask for confirmation when updating the Rep Account Status from Active to Historical
    if (value === historicalStatusId && initialValues.McsStatusMasterId === ActiveMemberStatusId && (initialValues.MemberTypeId === MembershipTypes.Representative || initialValues.MemberTypeId === MembershipTypes.cooperativeAgreementRep)) {
        if(!!initialValues.OrgName){
            let orgName = initialValues.OrgName;
            let descMessage = getMessage(messageCodes, '9022.text');
            descMessage = descMessage.replace('@Organization', orgName);
            if (initialValues.MemberTypeId === MembershipTypes.cooperativeAgreementRep) {
                descMessage = descMessage.replace('@membertype', membershipTypeJSON.CooperativeAgreementRep.displayName);
            } else {
                descMessage = descMessage.replace('@membertype', membershipTypeJSON.represntative.displayName);
            }
            let historicalRadioLabel = getMessage(messageCodes, '9027.text');
            historicalRadioLabel = historicalRadioLabel.replace('@Organization', orgName);

            let activeRadioLabel = getMessage(messageCodes, '9024.text');
            activeRadioLabel = activeRadioLabel.replace('@Organization', orgName);

            self.setState({ descMessage, historicalRadioLabel, activeRadioLabel, showActiveRepWithActiveOrgConfirmation: true })
        }
        else{
            let updatedStatusId = historicalStatusId;
            self.props.change('McsStatusMasterId', updatedStatusId);
            self.setState({ showActiveRepWithActiveOrgConfirmation: false, selectedStatus: updatedStatusId });
        }
    } else if (value === ActiveMemberStatusId && initialValues.McsStatusMasterId !== ActiveMemberStatusId) {

        // Ask for confirmation when updating the Deceased Member to Active
        if (initialValues.HistoricalReasonId === DeceasedHistoricalReasonId) {
            let descMessage = getMessage(messageCodes, '9021.text');
            currentAction = 1;
            self.setState({ descMessage, showConfirmationModal: true })
        }
        // Ask for confirmation when updating the Rep Account Status from Historical to Active
        else if (initialValues.MemberTypeId === MembershipTypes.Representative || initialValues.MemberTypeId === MembershipTypes.cooperativeAgreementRep) {
            confirmationModalsForHistoricRep();
        }
        // Ask for confirmation when updating the Student Account Status from Historical to Active when Graduation date is past configured months
        else if (initialValues.MemberTypeId === MembershipTypes.Student) {
            self.setState({ showAddGraduationDateModal: true });
        }
        else {
            self.setState({ selectedStatus: value });
        }
    }
    else {
        self.setState({ selectedStatus: value });
    }
}

export const onCancelModal = () => {
    // Close the editable mode when cancelling the confirmation popup.
    self.props.onCloseEditAccountInfo();
}

export const onConfirmModal = () => {
    const { initialValues } = self.props;

    if (initialValues.MemberTypeId === MembershipTypes.Representative || initialValues.MemberTypeId === MembershipTypes.cooperativeAgreementRep) {
        if (currentAction === 1) {
            confirmationModalsForHistoricRep();
        } else {
            self.setState({ showAssociateOrgModal: true, showConfirmationModal: false });
        }
    } else if (initialValues.MemberTypeId === MembershipTypes.Student) {
        self.setState({ showAddGraduationDateModal: true, showConfirmationModal: false });
    } else {
        self.setState({ selectedStatus: ActiveMemberStatusId, showConfirmationModal: false });
    }
}

export const onCloseConfirmationModal = (value) => {
    let updatedStatusId = '';

    if (value) {
        if (value === 'true') {
            isOrgStatusHistorical = true;
        } else {
            isOrgStatusHistorical = false;
        }

        updatedStatusId = historicalStatusId;
    } else {
        updatedStatusId = ActiveMemberStatusId;
    }

    self.props.change('McsStatusMasterId', updatedStatusId);
    self.setState({ showActiveRepWithActiveOrgConfirmation: false, selectedStatus: updatedStatusId });
}

export const onCloseHistoricalRepWithOrgModal = (value) => {
    if (value === 0) {
        self.props.change('McsStatusMasterId', historicalStatusId);
        self.props.onCloseEditAccountInfo();
    } else if (value === 1) {
        self.setState({ selectedStatus: ActiveMemberStatusId });
        selectedOrgNumber = self.props.initialValues.OrgAccountNumber;
    } else if (value === 2) {
        self.setState({ showAssociateOrgModal: true })
    }

    self.setState({ showHistoricalRepWithOrgConfirmation: false });
}

export const onCloseAssociateOrgModal = (value) => {
    // If user has selected an organization
    if (value) {
        selectedOrgNumber = value;
        self.setState({ selectedStatus: ActiveMemberStatusId });
    } else {
        self.props.change('McsStatusMasterId', historicalStatusId);
    }

    self.setState({ showAssociateOrgModal: false })
}

export const onCloseAddGraduationDateModal = (value) => {
    if (value) {
        graduationDate = value;
        self.setState({ selectedStatus: ActiveMemberStatusId });
        self.setState({ showAddGraduationDateModal: false });
    } else {
        self.props.onCloseEditAccountInfo();
    }
}

// Validation method for validate all value as per requirement
export const validateHandler = (values, props) => {
    const errors = {};

    if (values.McsStatusMasterId !== ActiveMemberStatusId && !values.HistoricalReasonId) {
        errors.HistoricalReasonId = getMessage(props.messageCodes, '8124.text');
    }

    if ((!values.UpdateReason) || (values.UpdateReason && values.UpdateReason.trim().length === 0)) {
        errors.UpdateReason = getMessage(props.messageCodes, '8121.text');
    }

    return errors;
}

export const onSubmitHandler = (data) => {
    const { initialValues } = self.props;

    // Send API only in case user has updated atleast one prop
    if (data.McsStatusMasterId !== initialValues.McsStatusMasterId || data.PaidStatus !== initialValues.PaidStatus ||
        (initialValues.McsStatusMasterId !== ActiveMemberStatusId && data.HistoricalReasonId !== initialValues.HistoricalReasonId)) {
        let requestData = {
            MemberTypeId: data.MemberTypeId,
            StatusId: data.McsStatusMasterId,
            StatusIdOld: initialValues.McsStatusMasterId,
            activeStatusId,
            historicalStatusId,
            HistoricalReasonId: data.McsStatusMasterId === ActiveMemberStatusId ? null : data.HistoricalReasonId,
            PaidStatus: data.PaidStatus,
            GraduationDate: graduationDate,
            UpdateReason: data.UpdateReason,
            MemberId: initialValues.MemberId,
            CurrentFeeGroupId: data.FeeGroupId,
            StudentCommitteeManagement: self.state.flagFeature.StudentCommitteeManagement
        }

        if (data.MemberTypeId === MembershipTypes.Representative || initialValues.MemberTypeId === MembershipTypes.cooperativeAgreementRep) {
            requestData.IsOrgStatusHistorical = isOrgStatusHistorical;
            requestData.OrgAccountNumber = selectedOrgNumber;

            self.props.updateRepAccountStatus(data.AccountNumber, requestData, () => {
                updateAccountDetailsSuccessResponse(data.AccountNumber)
            })
        } else {
            self.props.updateAccountDetails(data.AccountNumber, requestData, () => {
                updateAccountDetailsSuccessResponse(data.AccountNumber)
            })
        }
    } else {
        self.props.displayNoChangesMadeMessage();
        self.props.onCloseEditAccountInfo();
    }
}

// Private functions
const updateAccountDetailsSuccessResponse = (accountNumber) => {
    self.props.getMemberDetails(accountNumber);
    self.props.onCloseEditAccountInfo();
}

const confirmationModalsForHistoricRep = () => {
    const { MemberTypeId } = self.props.initialValues
    if (!self.props.initialValues.OrgAccountNumber) {
        currentAction = 2;
        let descMessage = getMessage(self.props.messageCodes, '9033.text');
        if (MemberTypeId === MembershipTypes.cooperativeAgreementRep) {
            descMessage = descMessage.replaceAll('@membertype', membershipTypeJSON.CooperativeAgreementRep.displayName);
        } else {
            descMessage = descMessage.replaceAll('@membertype', membershipTypeJSON.represntative.displayName);
        }
        self.setState({ descMessage, showConfirmationModal: true })
    } else {
        let descMessage = getMessage(self.props.messageCodes, '9034.text');
        descMessage = descMessage.replace('@Organization', self.props.initialValues.OrgName);
        if (MemberTypeId === MembershipTypes.cooperativeAgreementRep) {
            descMessage = descMessage.replaceAll('@membertype', membershipTypeJSON.CooperativeAgreementRep.displayName);
        } else {
            descMessage = descMessage.replaceAll('@membertype', membershipTypeJSON.represntative.displayName);
        }
        self.setState({ descMessage, showHistoricalRepWithOrgConfirmation: true, showConfirmationModal: false });
    }
}
